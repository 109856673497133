import qs from 'qs';
import { bookingPaths } from '~pages/book/utils/paths';

// Types
import type { IkeaPhase } from '~services/IkeaPhaseService';
type BookingQueryParams = {
  form_referrer?: string;
  job_draft_guid?: string;
  job_draft_source?: string;
};

export const FURNITURE_ASSEMBLY_CATEGORY = 33;
export const IKEA_CATEGORY = 1104;
export const IKEA_GM_CATEGORY = 1107;

const IKEA_CATEGORY_IDS = [IKEA_CATEGORY, IKEA_GM_CATEGORY];

// TODO: Replace 'qs' with URLSearchParams
export const appendQueryParams = (url: string, newParams: Object) => {
  const [path, queryString] = url.split('?');
  const params = qs.parse(queryString);
  return (
    path + qs.stringify({ ...params, ...newParams }, { addQueryPrefix: true })
  );
};

export const getIkeaBookingUrlWithIkeaPhase = (
  categoryId: number,
  ikeaPhase: IkeaPhase,
  query?: BookingQueryParams
) => {
  let bookingUrl: string = '';

  switch (ikeaPhase) {
    case 'phase_0_1107':
      bookingUrl =
        '/tasks/r/form?' +
        new URLSearchParams({ ...query, category_id: categoryId.toString() });
      break;
    case 'phase_article_pricing':
    case 'phase_2':
      bookingUrl =
        '/ikea/search' + (query ? `?${new URLSearchParams(query)}` : '');
      break;
    default:
      bookingUrl =
        '/ikea/hire' + (query ? `?${new URLSearchParams(query)}` : '');
      break;
  }

  return bookingUrl;
};

export const getIkeaBookingUrlWithLocale = (
  categoryId: number,
  locale: string,
  query?: BookingQueryParams
) => {
  if (categoryId === 1104) {
    const subpath = ['en-US', 'en-CA', 'fr-CA'].includes(locale)
      ? 'search'
      : 'hire';
    return `/ikea/${subpath}` + (query ? `?${new URLSearchParams(query)}` : '');
  }

  return (
    '/tasks/r/form?' +
    new URLSearchParams({ ...query, category_id: categoryId.toString() })
  );
};

const getIkeaBookingUrl = (
  categoryId: number,
  ikeaPhase: IkeaPhase,
  locale: string,
  query?: BookingQueryParams
) => {
  return ikeaPhase
    ? getIkeaBookingUrlWithIkeaPhase(categoryId, ikeaPhase, query)
    : getIkeaBookingUrlWithLocale(categoryId, locale, query);
};

export const getBookingFlowUrl = (
  categoryId: number,
  ikeaPhase: IkeaPhase,
  locale: string,
  taskTemplateId: number,
  query?: BookingQueryParams
) => {
  if (IKEA_CATEGORY_IDS.includes(categoryId)) {
    return getIkeaBookingUrl(categoryId, ikeaPhase, locale, query);
  }

  return appendQueryParams(bookingPaths.details(taskTemplateId), query || {});
};
