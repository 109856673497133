// External
import ButtonChoice from '@taskrabbit/meadow-web/lib/ButtonChoice';
import Grid from '@taskrabbit/meadow-web/lib/Grid';

// Internal
import { DesktopWrapper, MobileWrapper } from '../index.styled';
import useSegment from '~hooks/useSegment';
import { trpc } from '~utils/trpc';
import {
  MARKETING_CATEGORY_FAMILY_ICONS_SECTION,
  PAGE_HOME,
  RECOMMENDATION_CATEGORY_PICKED,
} from '~hooks/useSegment/eventNames';
import { appendQueryParams } from '~pages/homepage/utils/helpers';

// Types
import type { Link } from '~server/types/content';

const ChildCategoryButtonRow = ({
  categories,
  categoryFamilyName,
}: {
  categories: Link[];
  categoryFamilyName: string;
}) => {
  const { trackEvent } = useSegment();
  const trpcContext = trpc.useContext();

  const sendSegmentAnalytics = async (linkData: Link) => {
    const queryParams = new URL(linkData.linkUrl).searchParams;
    const bootstrapData = await trpcContext.page.home.bootstrap.fetch();
    const metro = bootstrapData.bff?.metro;

    trackEvent({
      name: RECOMMENDATION_CATEGORY_PICKED,
      properties: {
        job_category: null,
        job_category_id: null,
        marketing_category_family_name: categoryFamilyName,
        metro: metro?.name ?? null,
        metro_id: metro?.id ?? null,
        page: PAGE_HOME,
        task_template_id: queryParams.get('task_template_id'),
        task_template_name: linkData.linkText,
        trigger_location: MARKETING_CATEGORY_FAMILY_ICONS_SECTION,
      },
    });
  };

  const categoryButtons = categories.map((category) => ({
    ...category,
    linkUrl: appendQueryParams(category.linkUrl, {
      form_referrer: 'homepage_v2_hero_buttons',
    }),
  }));

  return (
    <Grid
      container
      data-testid="category-button-row"
      spacing={{ md: 3, xs: 2 }}
      sx={{ mb: 4 }}
    >
      {categoryButtons.map((categoryButton) => (
        <Grid
          item
          key={categoryButton.linkText}
          onClick={() => sendSegmentAnalytics(categoryButton)}
        >
          <DesktopWrapper>
            <ButtonChoice href={categoryButton.linkUrl}>
              {categoryButton.linkText}
            </ButtonChoice>
          </DesktopWrapper>
          <MobileWrapper>
            <ButtonChoice href={categoryButton.linkUrl} size="small">
              {categoryButton.linkText}
            </ButtonChoice>
          </MobileWrapper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ChildCategoryButtonRow;
