// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import Link from 'next/link';
import { useState } from 'react';

// Internal
import useSegment from '~hooks/useSegment';
import { trpc } from '~utils/trpc';
import type { HomeIndexData } from '~server/trpc/router/page/home/types';
import {
  PAGE_HOME,
  POPULAR_PROJECTS_SECTION,
  RECOMMENDATION_CATEGORY_PICKED,
} from '~hooks/useSegment/eventNames';
import ServiceCard from '~components/marketing/ServiceCard';

// Type
type GridItemProps = {
  $isMobileHidden?: boolean;
};

// Styled Components
const PopularProjectsContainer = styled(Container)(
  ({ theme: { breakpoints, spacing } }) => ({
    paddingBottom: spacing(6),
    paddingTop: spacing(6),

    [breakpoints.up('md')]: {
      paddingBottom: spacing(11),
      paddingTop: spacing(11),
    },
  })
);

const Title = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { impact },
      },
      spacing,
    },
  }) => ({
    color: impact.main,
    marginBottom: spacing(2.5),

    [breakpoints.up('md')]: {
      marginBottom: spacing(3.75),
    },
  })
);

const GridItem = styled(Grid)<GridItemProps>(
  ({ $isMobileHidden, theme: { breakpoints } }) => ({
    display: $isMobileHidden ? 'none' : 'initial',

    [breakpoints.up('sm')]: {
      display: 'initial',
    },
  })
);

const ProjectWrapper = styled(Link)`
  display: block;
  text-decoration: none;
`;

const SeeMoreProjectsLabel = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { primary },
      },
    },
  }) => ({
    border: 'none',
    color: primary.main,
    width: '100%',

    [breakpoints.up('sm')]: {
      display: 'none',
    },
  })
);

const gridContainerConfig = {
  columnSpacing: { lg: 5, sm: 3, xs: 0 },
  container: true,
  rowSpacing: { lg: 7, sm: 4, xs: 3 },
};

const gridItemConfig = {
  item: true,
  lg: 3,
  md: 4,
  sm: 6,
  xs: 12,
};

const PopularProjects = ({
  linkCards,
  header,
  seeMoreText,
}: HomeIndexData['popularProjects']) => {
  const [allProjectsVisible, setAllProjectsVisible] = useState(false);
  const { trackEvent } = useSegment();
  const trpcContext = trpc.useContext();

  const projectClickHandler = async (url: string, header: string = '') => {
    const bootstrapData = await trpcContext.page.home.bootstrap.fetch();
    const metro = bootstrapData.bff?.metro;
    trackEvent({
      name: RECOMMENDATION_CATEGORY_PICKED,
      properties: {
        job_category: null,
        job_category_id: null,
        link_label: header,
        metro: metro?.name ?? null,
        metro_id: metro?.id ?? null,
        page: PAGE_HOME,
        task_template_id: url.match(/\d+/g)?.pop(),
        trigger_location: POPULAR_PROJECTS_SECTION,
      },
    });

    window.location.href = url;
  };

  return (
    <PopularProjectsContainer data-testid="popular-projects-section">
      <Title variant="h2">{header}</Title>
      <Grid {...gridContainerConfig}>
        {linkCards.map((project, index) => (
          <GridItem
            $isMobileHidden={!allProjectsVisible && index > 3}
            key={project.header}
            {...gridItemConfig}
          >
            <ProjectWrapper
              href={project.linkUrl}
              onClick={(e) => {
                e.preventDefault();
                projectClickHandler(project.linkUrl, project.header);
              }}
            >
              <ServiceCard {...project} />
            </ProjectWrapper>
          </GridItem>
        ))}
      </Grid>
      {allProjectsVisible ? null : (
        <SeeMoreProjectsLabel
          onClick={() => setAllProjectsVisible(true)}
          variant="button1"
        >
          {seeMoreText}
        </SeeMoreProjectsLabel>
      )}
    </PopularProjectsContainer>
  );
};

export default PopularProjects;
